.bottom-section__divider {
    background-color: white;
    height: 200px;
    position: absolute;
    top: calc(100% - 200px);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
    width: 100%;
    z-index: 20; }

.top-section__divider {
    background-color: white;
    height: 200px;
    position: absolute;
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 0 0);
    width: 100%;
    z-index: 20; }

