.logo-container {
    padding: 2rem;
    z-index: 30; }

.logo-container__border {
    padding: 2rem;
    margin-bottom: 200px;
    border: 1px solid $color-primary;
    z-index: 30; }

.logo__name {
    font-size: 5rem;
    color: $color-lightest; }

.logo__divider {
    width: 10rem;
    background-color: $color-primary;
    height: 0.25rem;
    margin-top: 2rem;
    display: inline-block; }

.logo__slogan {
    font-size: 1rem;
    color: $color-lightest;
    display: inline-block;
    float: right;
    font-size: 1.5rem;
    margin-top: 1rem; }
