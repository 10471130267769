/*** Параметры ***/

// Цвета
$color-primary: #7A63FF;
$color-secondary: #888;
$color-light: #EEE;
$color-lightest: #efefef;
$color-brand: #239bf6;
$color-dark: #1E1E1E;
$color-grey: #676767;

// Цвета брендов
$color-facebook: #3b5998;
$color-feedly: #2bb24c;
$color-github: #333;
$color-google: #dc4e41;
$color-instagram: #3f729b;
$color-linkedin: #0077b5;
$color-medium: #00ab6b;
$color-messenger: #0084ff;
$color-rss: #f26522;
$color-spotify: #2ebd59;
$color-twitter: #55acee;

// Рамки
$border-light: solid 1px rgba(0, 0, 0, .05);

// Размер шрифта
$font-size-biggest: 2.8rem;
$font-size-largest: 2.3rem;
$font-size-large: 1.2rem;
$font-size-base: 1rem;
$font-size-small: .9rem;
$font-size-smallest: .75rem;

// Отступы
$spacing-small: .75rem;
$spacing-single: 1rem;
$spacing-double: 2rem;
$spacing-triple: 3rem;
$spacing-big: 6rem;

// Media queries
$desktop: 800px;
$tablet: 600px;
$mobile: 480px;

// Шрифты
$main-font: 'Open Sans',sans-serif;
$header-font: 'Inter', sans-serif;

// Анимация
$anime-in: .4s;
$anime-out: .5s;
