// Portfolio Style
// Version: 1.1
// Author: Aivengo stream

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Open+Sans:wght@300;400;700&display=swap');

html {
  font-family: $main-font; }

h1, h2, h3, h4, h5 {
  font-family: $header-font; }

section {
  position: relative; }

.section-container {
  position: relative;
  display: flex;
  flex-direction: column; }
