@media only screen and (min-device-pixel-ratio: 1.25) {
    .parallax-section {
        background-image: url(/public/parallax/parallax.png); } }

.parallax-section {
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url(./img/parallax/parallax.png);
    perspective: 1px;
    overflow-x: hidden {
     overflow-y: auto; }
    &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #000;
        opacity: 0.65;
        content: "";
        z-index: 10; } }


