.contact__link {
    display: block;
    width: 100px;
    height: 100px;
    background-color: $color-dark;
    border-radius: 50px;
    margin: 3rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3rem;
    transition: 1s;
    &:hover {
        background-color: $color-primary;
        transition: 1s; } }

.link-mail {
    background-image: url(./img/email.png); }

.link-telegram {
    background-image: url(./img/telegram.png); }

.link-github {
    background-image: url(./img/github.png); }
