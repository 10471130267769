.Skills {
    background-color: $color-dark; }

.skills-table-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: calc( 3rem + 200px ); }
.skills-table {}

.skills-table > tr > th {
    padding: 2rem; }

.skills-table > tr > td {
    padding: 2rem; }

.skills-table > tr {
    border-bottom: 1px solid #8E8E8E; }

.skills-table > tr:last-child {
    border-bottom: 0px; }

.level__header {
    font-size: 2.5rem;
    color: #fff;
    font-family: $header-font;
    font-weight: 700;
    text-align: left; }
.skill__header {
    font-size: 2rem;
    color: #fff;
    font-family: $header-font;
    font-weight: 700;
    width: 200px; }
.cell--inversed {
    background-color: rgba(122, 99, 255, 0.4); }
.cell--selected {
    background-image: url(./img/skillselected.svg);
    background-position: center center;
    background-repeat: no-repeat; }
