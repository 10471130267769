canvas {
  display: block;
  vertical-align: bottom; }

#tsparticles {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; }
