footer {
    background-color: $color-dark;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff; }
.footer__text {
    font-size: 1rem;
    font-weight: 300;
    line-height: 40px; }
