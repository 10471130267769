.navbar-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
    background-color: $color-dark;
    position: fixed;
    z-index: 100;
    transition: 1s;
    transition-timing-function: ease-in; }
.navbar-down {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: $color-dark;
    position: fixed;
    z-index: 100;
    transition: 1s;
    transition-timing-function: ease-out; }
.navbar__link {
    position: relative;
    padding: 2rem;
    color: white;
    text-decoration: none;
    text-transform: uppercase;

    &:focus {
        color: $color-primary; }

    &:before {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 2px;
        background: #FFF;
        content: "";
        opacity: 0;
        transition: all 0.3s;
        top: 1rem;
        transform: translateY(-10px); }
    &:after {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 2px;
        background: #FFF;
        content: "";
        opacity: 0;
        transition: all 0.3s;
        bottom: 1rem;
        transform: translateY(10px); }
    &:hover:before {
        opacity: 1;
        transform: translateY(0px); }
    &:hover:after {
        opacity: 1;
        transform: translateY(0px); } }
