.service-item-wrapper {
    margin-top: 300px;
    position: relative;
    height: 300px;
    &:after {
        content: "";
        position: absolute;
        top: calc(100% + 95px);
        left: 50%;
        height: 105px;
        width: 80px;
        background-image: url(./img/arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateX(-50%); } }
.service-item-wrapper:last-child {
    &:after {
        display:none {} } }

.service-item-svg-1 {
    right: calc(50% + 120px);
    background-image: url(./img/item-1.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
.service-item-svg-2 {
    left: calc(50% + 120px);
    background-image: url(./img/item-2.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
.service-item-svg-3 {
    right: calc(50% + 120px);
    background-image: url(./img/item-3.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
.service-item-svg-4 {
    left: calc(50% + 120px);
    background-image: url(./img/item-4.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
.service-item-svg {
    width: 500px;
    height: 300px;
    position: absolute; }
.service-item-desc-left {
    text-align: left;
    width: 800px;
    left: 50%;
    top: 0;
    position: absolute; }
.service-item-desc-right {
    text-align: right;
    width: 800px;
    right: 50%;
    top: 0;
    position: absolute; }
.service-item-header {
    font-size: 50px;
    font-weight: 600; }
.service-item-text {
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    margin-top: 40px; }

