.header__wrapper {
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
.header__text {
    font-size: 3rem;
    font-weight: 600;
    font-family: $main-font; }
.header__text-inversed {
    margin-top: 200px;
    font-size: 3rem;
    font-weight: 600;
    color: $color-primary;
    font-family: $main-font; }
.header__divider {
    height: 2px;
    width: 100%;
    margin-top: 20px;
    background-color: $color-grey;
    opacity: 0.5; }
